import { mapApiToCemeteryInfo } from 'src/adapters';
import { doGet } from 'src/api/axios-config';
import { ApiRoute } from 'src/constants';
import type { IApiCemetery, IApiSection, IFilter } from 'src/interfaces';

async function fetchCemeterySections() {
  try {
    const result = await doGet<IApiSection[]>(ApiRoute.CEMETERY_SECTIONS);
    return result.data || null;
  } catch (err: any) {
    console.log(err);
    return null;
  }
}

export async function fetchCemeteryInfo() {
  try {
    const result = await doGet<IApiCemetery>(ApiRoute.CEMETERY);

    if (result.data) {
      const sectionsInfo = await fetchCemeterySections();
      return mapApiToCemeteryInfo(result.data, sectionsInfo);
    } else {
      return null;
    }
  } catch (err: any) {
    console.log(err);
    return null;
  }
}

export async function fetchCemeteryFilters() {
  try {
    const result = await doGet<IFilter>(ApiRoute.CEMETERY_FILTERS);
    return result.data || null;
  } catch (err: any) {
    console.log(err);
    return null;
  }
}
