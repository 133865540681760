import React from 'react';

import Button from 'src/components/ui/button';
import { ReactComponent as Icon } from 'src/assets/icons/pin-outlined.svg';

import { useLocalization } from 'src/contexts/localization-context';
import type { NoLotsProps } from './no-lots.props';

import './no-lots.scss';

const NoLots = ({ onButtonClick }: NoLotsProps) => {
  const { getLocalization } = useLocalization();
  return (
    <section className="no-lots">
      <Icon className="no-lots__img" height={100} width={100} />
      <div className="no-lots__prompt">
        <h1 className="no-lots__title">{getLocalization('No Lots Found')}</h1>
        <p className="no-lots__text">
          {getLocalization('Try to select a another lot type or change the price parameters')}
        </p>
      </div>
      <Button
        className="no-lots__btn"
        label={getLocalization('Show All Lots')}
        theme="outlined"
        size="oversize"
        onClick={onButtonClick}
      />
    </section>
  );
};

export default React.memo(NoLots);
