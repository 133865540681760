import React, { KeyboardEvent } from 'react';
import classNames from 'classnames';

import ImageWithFallback from 'src/components/common/image-with-fallback';
import noImage from 'src/assets/images/no-image.svg';

import popupStore from 'src/stores/popup-store';

import { Key, PopupName } from 'src/constants';
import useDraggableScroll from 'src/hooks/use-draggable-scroll';
import { getImagesAlt } from 'src/utils';
import type { LotGalleryProps } from './lot-gallery.props';

import './lot-gallery.scss';

function LotGallery({ listImages }: LotGalleryProps) {
  const listRef = React.useRef(null);
  const { onMouseDown } = useDraggableScroll(listRef, { direction: 'horizontal' });

  let initialPosition = { idx: -1, mouseX: 0, mouseY: 0 };

  const numberImages = listImages.length;

  const handleImageEnterUp = (evt: KeyboardEvent<HTMLButtonElement>, idx: number) => {
    if (evt.key === Key.ENTER) {
      popupStore.showPopup(PopupName.GALLERY, {
        images: listImages,
        imagesAlt: 'Photo',
        initialIndex: idx,
      });
    }
  };

  const handleImageMouseDownWrapper =
    (idx: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (event.button === 0) {
        initialPosition = {
          idx,
          mouseX: event.clientX,
          mouseY: event.clientY,
        };
      }
    };

  const handleImageMouseUpWrapper =
    (idx: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const dx = event.clientX - initialPosition.mouseX;
      const dy = event.clientY - initialPosition.mouseY;

      if (initialPosition.idx === idx && dx === 0 && dy === 0 && event.button === 0) {
        popupStore.showPopup(PopupName.GALLERY, {
          images: listImages,
          imagesAlt: 'Photo',
          initialIndex: idx,
        });
      }

      initialPosition = { idx: -1, mouseX: 0, mouseY: 0 };
    };

  return (
    <div
      className={classNames(
        'lot-gallery no-scrollbar',
        numberImages === 1 && 'lot-gallery_count_single',
        numberImages === 2 && 'lot-gallery_count_double'
      )}
      onMouseDown={onMouseDown}
      ref={listRef}
    >
      {listImages.map(({ originImg }, idx) => {
        return (
          <button
            key={originImg + 'Index' + idx}
            className="lot-gallery__photo"
            onMouseDown={handleImageMouseDownWrapper(idx)}
            onMouseUp={handleImageMouseUpWrapper(idx)}
            onKeyUp={(evt: KeyboardEvent<HTMLButtonElement>) => handleImageEnterUp(evt, idx)}
          >
            <ImageWithFallback
              alt={getImagesAlt('Photo', idx, numberImages)}
              className="lot-gallery__image"
              src={originImg}
              fallbackSrc={noImage}
            />
          </button>
        );
      })}
    </div>
  );
}

export default LotGallery;
