import React from 'react';
import classNames from 'classnames';

import type { DropdownProps } from './dropdown.props';

import './dropdown.scss';

const Dropdown = ({ children, className }: DropdownProps) => {
  return <div className={classNames('dropdown', className)}>{children}</div>;
};

export default React.memo(Dropdown);
