import type { ILot } from 'src/interfaces';

export const LOT_DETAILS: {
  label: string;
  field: keyof Pick<ILot, 'block' | 'bank' | 'rights' | 'row' | 'tier'>;
}[] = [
  { label: 'Block', field: 'block' },
  { label: 'Tier', field: 'tier' },
  { label: 'Row', field: 'row' },
  { label: 'Bank', field: 'bank' },
  { label: 'Rights', field: 'rights' },
];
