import React from 'react';
import { observer } from 'mobx-react-lite';

import { useLocalization } from 'src/contexts/localization-context';
import type { DetailsSectionProps } from './details-section.props';

import './details-section.scss';

const DetailsSection = observer(({ title, data }: DetailsSectionProps) => {
  const { getLocalization } = useLocalization();
  return (
    <section className="details-section">
      <h2 className="details-section__title">{title}</h2>

      <dl className="details-section__text-wrapper">
        {data.map(({ label, value }) => (
          <React.Fragment key={label}>
            <dt className="details-section__label">{getLocalization(label)}:</dt>
            <dd className="details-section__value">{getLocalization(value)}</dd>
          </React.Fragment>
        ))}
      </dl>
    </section>
  );
});

export default DetailsSection;
