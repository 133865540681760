import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import PopupCancelRequest from '../popup-cancel-request';
import PopupGallery from '../popup-gallery';
import PopupShare from '../popup-share';
import PopupSuccessRequest from '../popup-success-request';
import PopupWarn from '../popup-warn';

import popupStore from 'src/stores/popup-store';

import { Key, PopupName } from 'src/constants';
import useKeyUp from 'src/hooks/use-key-up';
import useOnClickOutside from 'src/hooks/use-on-click-outside';
import useUnmountAnimation from 'src/hooks/use-unmount-animation';
import useWindowScrollBlock from 'src/hooks/use-window-scroll-block';
import type { ICommonPopupProps } from 'src/interfaces';
import type { PopupProps } from './popup.props';

import './popup.scss';

const POPUP_COMPONENTS: Record<PopupName, React.FC<ICommonPopupProps>> = {
  [PopupName.CANCEL_REQUEST]: PopupCancelRequest,
  [PopupName.GALLERY]: PopupGallery,
  [PopupName.SUCCESS_REQUEST]: PopupSuccessRequest,
  [PopupName.WARN]: PopupWarn,
  [PopupName.SHARE]: PopupShare,
};

const Popup = observer(({ popupName }: PopupProps) => {
  const popupRef = React.useRef(null);

  const { className, isVisible, onClose } = popupStore.getPopupState(popupName);

  const closePopup = () => {
    popupStore.hidePopup(popupName);
    onClose?.();
  };

  const { unmountComponent, handleAnimationEnd, isUnmounting } = useUnmountAnimation(closePopup);
  useOnClickOutside(popupRef, unmountComponent);
  useKeyUp(Key.ESCAPE, unmountComponent);

  useWindowScrollBlock(true);

  const PopupComponent = POPUP_COMPONENTS[popupName] || null;

  return (
    <div
      className={classNames(
        'popup',
        className,
        isUnmounting && 'popup_hiding',
        `popup_${popupName}`,
        'scrollbar'
      )}
    >
      <div className="popup__wrapper scrollbar" ref={popupRef} onAnimationEnd={handleAnimationEnd}>
        {isVisible && <PopupComponent handleCloseButtonClick={unmountComponent} />}
      </div>
    </div>
  );
});

export default Popup;
