import React from 'react';
import classNames from 'classnames';

import type { FilterChipProps } from './filter-chip.props';

import './filter-chip.scss';

const FilterChip = ({ className, isActive, isDisabled, text, onClick }: FilterChipProps) => {
  const filterChipClasses = classNames(
    'filter-chip',
    isActive && 'filter-chip_active',
    isDisabled && 'filter-chip_disabled',
    className
  );

  return (
    <button className={filterChipClasses} disabled={isDisabled} onClick={onClick}>
      {text}
    </button>
  );
};

export default FilterChip;
