import React from 'react';
import { observer } from 'mobx-react-lite';

import ImageViewer from 'src/components/common/image-viewer';
import IconButton from 'src/components/ui/icon-button';
import { ReactComponent as CrossIcon } from 'src/assets/icons/cross-icon.svg';

import popupStore from 'src/stores/popup-store';

import { PopupName } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import type { PopupGalleryProps } from './popup-gallery.props';

const PopupGallery = observer(({ handleCloseButtonClick }: PopupGalleryProps) => {
  const { images, imagesAlt, initialIndex } = popupStore.getPopupState(PopupName.GALLERY);
  const { getLocalization } = useLocalization();
  return (
    <>
      <IconButton
        ariaLabel={getLocalization('Close Gallery')}
        className="popup__close-button"
        icon={<CrossIcon className="popup__close-button-icon" />}
        onClick={handleCloseButtonClick}
      />
      <ImageViewer images={images} initialIndex={initialIndex} imagesAlt={imagesAlt} />
    </>
  );
});

export default PopupGallery;
