export const BASE_ROUTE = process.env.PUBLIC_URL || '/';
export const PUBLIC_URL = process.env.PUBLIC_URL ?? '';
export const CEMETERY_LINK = window.CEMETERY_LINK ?? '';
export const CEMETERY_NAME = window.CEMETERY_NAME ?? '';
export const GTM_ID = window.GOOGLE_TAG_MANAGER_ID;
export const THEME_REQUIRED_PROPERTY = '--color-theme-accent';

export const CONFIG_REQUIRED_PARAMS: (keyof Window)[] = [
  'API_URL',
  'CEMETERY_ID',
  'CURRENCY',
  'GOOGLE_MAP_API_KEY',
  'MAIN_PAGE_DESCRIPTION',
  'MAIN_PAGE_TITLE',
  'PRIVACY_POLICY_URL',
  'TERMS_OF_USE_URL',
  'TYPE_OF_LOTS',
];

export const CONFIG_REQUIRED_PARAMS_MAPPED = CONFIG_REQUIRED_PARAMS.map((item) => ({
  name: item,
  value: window[item],
}));
