import React from 'react';
import InputMask from 'react-input-mask';
import classNames from 'classnames';

import InputPlaceholder from 'src/components/ui/input-placeholder';
import Label from 'src/components/ui/label';
import TextError from 'src/components/ui/text-error';

import { CYRILLIC_SYMBOLS_REG_EXP } from 'src/constants';
import type { InputProps } from './input.props';

import './input.scss';

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      autoComplete,
      className,
      hasCounter,
      icon,
      inputMode = 'text',
      isDisabled = false,
      isValid = true,
      label,
      mask = '',
      maskPlaceholder = '',
      maxLength,
      onBlur,
      onChange,
      onFocus,
      onKeyUp,
      placeholder,
      rows,
      size = 'fullsize',
      desktopSize,
      textError = '',
      type = 'text',
      value,
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const isHidePlaceholder = isFocused || !!value;

    const handleFocus = () => {
      setIsFocused(true);
      onFocus?.();
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>) => {
      setIsFocused(false);
      onBlur?.(event);
    };

    const handleChange = (
      event: React.SyntheticEvent<HTMLInputElement> | React.SyntheticEvent<HTMLTextAreaElement>
    ) => {
      const inputValue = event.currentTarget.value;
      const testedValue = inputValue.replace(CYRILLIC_SYMBOLS_REG_EXP, '');

      if (inputValue === testedValue) {
        onChange(testedValue);
      }
    };

    const renderInputMask = () => (
      <>
        <InputMask
          autoComplete={autoComplete}
          className="input__field"
          dir="auto"
          disabled={isDisabled}
          inputMode={inputMode}
          mask={mask}
          maskPlaceholder={maskPlaceholder}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyUp={onKeyUp}
          type="text"
          value={value}
        />
        {icon}
      </>
    );

    const renderInput = () => {
      return rows ? (
        <textarea
          autoComplete={autoComplete}
          className="scrollbar input__textarea"
          dir="auto"
          disabled={isDisabled}
          inputMode={inputMode}
          maxLength={maxLength}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          rows={rows}
          value={value}
        />
      ) : (
        <>
          {icon}
          <input
            autoComplete={autoComplete}
            className="input__field"
            dir="auto"
            disabled={isDisabled}
            inputMode={inputMode}
            maxLength={maxLength}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onKeyUp={onKeyUp}
            type={type}
            ref={ref}
            value={value}
          />
        </>
      );
    };

    const inputClasses = classNames(
      'input',
      className,
      !rows && `input_${size}`,
      !rows && desktopSize && `input_desktop-${desktopSize}`,
      {
        input_label: !!label,
        input_placeholder: !!placeholder,
        input_mask: !!mask,
        input_disabled: isDisabled,
        input_focus: isFocused,
        input_icon: !!icon,
        input_multi: !!rows,
        input_invalid: !isValid && !isFocused,
        'input_with-counter': hasCounter,
      }
    );

    const labelClasses = classNames('input__label', {
      label_disabled: isDisabled,
      label_invalid: !isValid && !isFocused,
      label_placeholder: !value && !isFocused && !placeholder,
    });

    return (
      <div className={inputClasses}>
        {label && <Label label={label} className={labelClasses} />}
        {placeholder && <InputPlaceholder placeholder={placeholder} hide={isHidePlaceholder} />}
        {mask ? renderInputMask() : renderInput()}
        {hasCounter && (
          <div className="input__counter">
            {value.length}
            {maxLength ? `/${maxLength}` : ''}
          </div>
        )}
        {!isValid && !isFocused && !isDisabled ? <TextError textError={textError} /> : null}
      </div>
    );
  }
);

export default React.memo(Input);
