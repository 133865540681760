import { ErrorText } from 'src/constants';
import type { IContactPersonRequiredFields } from 'src/interfaces';

export const getTextError = (field: IContactPersonRequiredFields, value: string) => {
  if (!value) {
    return ErrorText.FIELD_IS_REQUIRED;
  }

  switch (field) {
    case 'dateOfBirth':
      return ErrorText.DATE_ERROR;
    case 'email':
      return ErrorText.EMAIL_FORMAT_INVALID_ERROR;
    case 'firstName':
    case 'lastName':
      return ErrorText.NAME_FORMAT_INVALID_ERROR;
    case 'phone':
      return ErrorText.PHONE_NUMBER_ERROR;
    default:
      return '';
  }
};
