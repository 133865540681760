import { mapApiToLot } from 'src/adapters';
import { doGet } from 'src/api/axios-config';
import { ApiRoute } from 'src/constants';
import type { IApiLot } from 'src/interfaces';

export async function fetchLots(params: { [propName: string]: string }) {
  try {
    const result = await doGet<IApiLot[]>(ApiRoute.LOTS, {
      params,
    });

    return result.data?.map(mapApiToLot) || null;
  } catch (err: any) {
    console.log(err);
    return null;
  }
}

export async function fetchLotById(id: string) {
  try {
    const result = await doGet<IApiLot>(`${ApiRoute.LOT}/${id}`);

    if (result.data) {
      return mapApiToLot(result.data);
    } else {
      return null;
    }
  } catch (err: any) {
    console.log(err);
    return null;
  }
}
