import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Footer from 'src/components/common/footer';
import Loader from 'src/components/common/loader';
import IconButton from 'src/components/ui/icon-button';
import MapLotsList from './components/map-lots-list';
import NoLots from '../no-lots';
import { ReactComponent as Arrow } from 'src/assets/icons/chevron-down.svg';

import filterStore from 'src/stores/filter-store';

import { FooterTheme } from 'src/constants';
import { useHeader } from 'src/contexts/header-context';
import useIsLaptop from 'src/hooks/use-is-laptop';
import useLotsHeight from 'src/hooks/use-lots-height';
import useWindowSize from 'src/hooks/use-window-size';
import type { MapLotsProps } from './map-lots.props';

import './map-lots.scss';

const LIST_HEIGHT_INACCURANCY_VALUE = 10;
const LOT_AVERAGE_HEIGHT = 220;
const LOT_SHOW_COUNT = 10;

const MapLots = observer(
  ({ className, handleCardClick, isLoading, lots, selectedLot }: MapLotsProps) => {
    const { headerHeight } = useHeader();
    const listRef = useRef<HTMLElement>(null);
    const [allLots, setAllLots] = useState(lots);
    const { clearFilters } = filterStore;
    const [showCount, setShowCount] = useState(LOT_SHOW_COUNT);
    const [isShownScrollButton, setIsShownScrollButton] = useState(false);
    const isLaptop = useIsLaptop();
    const lotsToShow = allLots.slice(0, showCount);
    const windowSize = useWindowSize();
    useLotsHeight(listRef, headerHeight, windowSize.width);

    /**
     * Adds more items to shown plot list when scrolled to the bottom of block
     */
    const handleScroll = () => {
      const sectionRef = listRef.current;
      if (!sectionRef) {
        return;
      }

      if (sectionRef.scrollTop > LOT_AVERAGE_HEIGHT && !isShownScrollButton) {
        setIsShownScrollButton(true);
      }

      if (sectionRef.scrollTop < LOT_AVERAGE_HEIGHT && isShownScrollButton) {
        setIsShownScrollButton(false);
      }

      if (
        sectionRef.offsetHeight + sectionRef.scrollTop >=
        sectionRef.scrollHeight - LIST_HEIGHT_INACCURANCY_VALUE
      ) {
        setShowCount((prevShowCount) => Math.min(prevShowCount + LOT_SHOW_COUNT, allLots.length));
      }
    };

    useEffect(() => {
      setAllLots(lots);
    }, [lots]);

    useEffect(() => {
      if (selectedLot) {
        const selectedLotIndex = allLots.findIndex((lot) => lot.id === selectedLot.id);
        if (showCount < selectedLotIndex + 1) {
          setShowCount(selectedLotIndex + 1);
        }
      }
    }, [selectedLot, allLots, showCount]);

    return (
      <section className={classNames('map-lots', className)} ref={listRef} onScroll={handleScroll}>
        {
          // eslint-disable-next-line no-nested-ternary
          isLoading ? (
            <Loader className="loader_position_overlay" />
          ) : lotsToShow.length > 0 ? (
            <>
              <MapLotsList
                isLaptop={isLaptop}
                lots={lotsToShow}
                selectedLot={selectedLot}
                onLotClick={handleCardClick}
              />
              {isShownScrollButton && !!lots.length && (
                <IconButton
                  className="map-lots__scroll"
                  ariaLabel="Scroll to top"
                  onClick={() => {
                    if (listRef.current) {
                      listRef.current.scrollTo({ top: 0, behavior: 'smooth' });
                    }
                  }}
                  icon={<Arrow />}
                />
              )}
            </>
          ) : (
            <NoLots onButtonClick={clearFilters} />
          )
        }
        {isLaptop || <Footer className="map-lots__footer" theme={FooterTheme.DARK} />}
      </section>
    );
  }
);

export default React.memo(MapLots);
