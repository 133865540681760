import React, { KeyboardEvent, useEffect, useRef } from 'react';
import classNames from 'classnames';

import ImageWithFallback from 'src/components/common/image-with-fallback';
import Button from 'src/components/ui/button';
import LotDetails from './components/lot-details';

import { AppRoute, Key, LotTypeShown, NoImageLot } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import useIsLaptop from 'src/hooks/use-is-laptop';
import { formatCents, getLotUrl } from 'src/utils';
import type { LotCardProps } from './lot-card.props';

import './lot-card.scss';

const LotCard = ({ lot, isSelected, onClick }: LotCardProps) => {
  const { id, type, number, price, previewImage } = lot;
  const itemRef = useRef<HTMLDivElement>(null);
  const { getLocalization } = useLocalization();
  const isLaptop = useIsLaptop();

  const handleLotKeyUp = (evt: KeyboardEvent<HTMLDivElement>) => {
    if (evt.key === Key.ENTER) {
      onClick(lot);
    }
  };

  useEffect(() => {
    if (isSelected && itemRef.current) {
      itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [isSelected]);

  return (
    <div
      className={classNames('lot-card', isSelected && 'lot-card_selected')}
      onClick={onClick(lot)}
      ref={itemRef}
      tabIndex={isLaptop && !isSelected ? 0 : -1}
      onKeyUp={handleLotKeyUp}
    >
      <div className="lot-card__img-container">
        <ImageWithFallback
          alt="Lot"
          className="lot-card__img"
          fallbackSrc={NoImageLot[type]}
          src={previewImage || NoImageLot[type]}
        />

        <div
          className={classNames('lot-card__img-info', !previewImage && 'lot-card__img-info_no-img')}
        >
          <span className="lot-card__img-info-value">{`${getLocalization(
            LotTypeShown[type]
          )} ${number}`}</span>
          <span className="lot-card__img-info-value">{formatCents(price)}</span>
        </div>
      </div>

      <div className="lot-card__content">
        <div className="lot-card__details-container">
          <p className="lot-card__details-title">{`${getLocalization(
            LotTypeShown[type]
          )} ${number}`}</p>

          <LotDetails lot={lot} />
        </div>

        <p className="lot-card__price">{formatCents(price)}</p>

        <div className="lot-card__information-buttons">
          <Button
            className="lot-card__information-button"
            tag="link"
            theme="filled"
            size="normal"
            desktopSize="flattened"
            href={`${AppRoute.RESERVATION_PAGE}/${id}`}
            label={getLocalization('Request')}
            target="_blank"
          />
          <Button
            className="lot-card__information-button"
            tag="link"
            theme="outlined"
            size="normal"
            desktopSize="flattened"
            href={getLotUrl(id)}
            label={getLocalization('More Info')}
            target="_blank"
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(LotCard);
