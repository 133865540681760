import React from 'react';

import FilterChip from 'src/components/common/filter-chip';

import { useLocalization } from 'src/contexts/localization-context';
import type { FilterChipsListProps } from './filter-chips-list.props';

import './filter-chips-list.scss';

const FilterChipsList = ({ activeItem, items, onSelectChip }: FilterChipsListProps) => {
  const { getLocalization } = useLocalization();
  return (
    <div className="filter-chips-list">
      {items.map(({ id, value }) => (
        <FilterChip
          key={id}
          className="filter-chips-list__item"
          isActive={activeItem === id}
          isDisabled={items.length === 1}
          onClick={() => onSelectChip(id)}
          text={getLocalization(value)}
        />
      ))}
    </div>
  );
};

export default React.memo(FilterChipsList);
