import React, { useEffect } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import IconButton from 'src/components/ui/icon-button';
import FiltersView from '../filters-view';
import MapFilters from '../map-filters';
import { ReactComponent as CrossIcon } from 'src/assets/icons/cross-icon.svg';
import { ReactComponent as FiltersIcon } from 'src/assets/icons/filters.svg';

import useIsLaptop from 'src/hooks/use-is-laptop';

import './map-filters-wrapper.scss';

const MapFiltersWrapper = observer(() => {
  const isLaptop = useIsLaptop();

  const [showFiltersModal, setShowFiltersModalStatus] = React.useState(false);

  useEffect(() => {
    if (showFiltersModal) {
      document.body.style.touchAction = 'none';
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.touchAction = 'unset';
      document.body.style.overflowY = 'auto';
    }

    return () => {
      document.body.style.touchAction = 'unset';
      document.body.style.overflowY = 'auto';
    };
  }, [showFiltersModal]);

  const closeModal = React.useCallback(() => {
    setShowFiltersModalStatus(false);
  }, []);

  const toggleModal = React.useCallback(() => {
    setShowFiltersModalStatus((prevState) => !prevState);
  }, []);

  const handleModalClick = React.useCallback(
    (evt: any) => {
      if (!evt.target.closest('.map-filters') && showFiltersModal) {
        closeModal();
      }
    },
    [closeModal, showFiltersModal]
  );

  if (isLaptop) {
    return <MapFilters isDesktop />;
  }

  return (
    <div className="map-filters-wrapper">
      <IconButton
        ariaLabel="Show filters menu"
        className={classNames(
          'map-filters-wrapper__toggle-icon',
          showFiltersModal && 'map-filters-wrapper__toggle-icon_open'
        )}
        icon={showFiltersModal ? <CrossIcon /> : <FiltersIcon />}
        onClick={toggleModal}
        size="normal"
      />
      <FiltersView />
      <div
        className={classNames(
          'map-filters-wrapper__modal',
          showFiltersModal && 'map-filters-wrapper__modal_shown'
        )}
        onClick={handleModalClick}
      >
        <MapFilters onClose={closeModal} />
      </div>
    </div>
  );
});

export default React.memo(MapFiltersWrapper);
