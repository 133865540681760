import { formatPhoneToApi, getAddress, getDOBFormatted } from 'src/utils';
import type { IApiContactPerson, IContactPerson } from 'src/interfaces';

const mapPersonToApi = ({
  address,
  apartment,
  dateOfBirth,
  email,
  firstName,
  lastName,
  phone,
}: IContactPerson): IApiContactPerson => {
  const trimmedApartment = apartment.trim();

  return {
    address: {
      ...address,
      ...(trimmedApartment ? { room: trimmedApartment } : null),
      formatted_address: getAddress(address, trimmedApartment),
    },
    date_of_birth: getDOBFormatted(dateOfBirth),
    email: email.trim(),
    firstname: firstName.trim(),
    lastname: lastName.trim(),
    phone: formatPhoneToApi(phone),
  };
};

export const mapLotToApi = (
  purchaser: IContactPerson,
  deedOwner: IContactPerson,
  lotId: string
) => ({
  cemetery: window.CEMETERY_ID,
  deed_owner: mapPersonToApi(deedOwner),
  lot: lotId,
  purchaser: mapPersonToApi(purchaser),
});
