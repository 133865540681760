import React from 'react';
import classNames from 'classnames';

import CemeteryLogoLink from './components/cemetery-logo-link';
import LogoText from './components/logo-text';

import { CEMETERY_NAME, IS_LOGO_TEXT_SEPARATED, LogoTextLocation, PUBLIC_URL } from 'src/constants';
import { getCemeteryLogoProps } from 'src/utils';
import type { CemeteryLogoProps } from './cemetery-logo.props';

import './cemetery-logo.scss';

const CemeteryLogo = ({ textLocation, isFooter, isColored }: CemeteryLogoProps) => {
  const { logoClasses, logoSrc, logoWidth, logoHeight } = getCemeteryLogoProps(
    textLocation,
    IS_LOGO_TEXT_SEPARATED,
    isColored
  );

  const cemeteryName = `${CEMETERY_NAME || 'Cemetery'} logo`;

  return textLocation === LogoTextLocation.BOTTOM ? (
    <div
      className={classNames(
        `cemetery-logo cemetery-logo_${textLocation}`,
        isColored && 'cemetery-logo_colored'
      )}
    >
      <img
        className={logoClasses}
        src={logoSrc}
        alt={cemeteryName}
        width={logoWidth}
        height={logoHeight}
      />
      {IS_LOGO_TEXT_SEPARATED && (
        <LogoText
          location={textLocation}
          title={window.APP_CEMETERY_LOGO_TITLE}
          subtitle={window.APP_CEMETERY_LOGO_SUBTITLE}
        />
      )}
    </div>
  ) : (
    <>
      <CemeteryLogoLink
        className={classNames(
          `cemetery-logo cemetery-logo_medium cemetery-logo_${textLocation}`,
          isFooter && 'cemetery-logo_medium_in-footer'
        )}
        isFooter={isFooter}
      >
        <img
          className={logoClasses}
          src={logoSrc}
          alt={cemeteryName}
          width={logoWidth}
          height={logoHeight}
        />
        {IS_LOGO_TEXT_SEPARATED && (
          <LogoText
            location={textLocation}
            title={window.APP_CEMETERY_LOGO_TITLE}
            subtitle={window.APP_CEMETERY_LOGO_SUBTITLE}
          />
        )}
      </CemeteryLogoLink>

      <CemeteryLogoLink
        className={classNames(
          `cemetery-logo cemetery-logo_small-${textLocation}`,
          isFooter && 'cemetery-logo_small_in-footer'
        )}
        isFooter={isFooter}
      >
        <img
          alt={cemeteryName}
          className="cemetery-logo__img"
          height={34}
          src={`${PUBLIC_URL}/logos/logo-small-colored.svg`}
          width={34}
        />
      </CemeteryLogoLink>
    </>
  );
};

export default CemeteryLogo;
