import { InputMaxLength, InputMinLength, Mask, Regex } from 'src/constants';

export const getIsDateValid = (date: string) => {
  return (
    Regex.DATE.test(date) &&
    date.length === Mask.DATE.length &&
    new Date(date) < new Date() &&
    new Date(date) > new Date('01/01/1880')
  );
};

export const getIsEmailValid = (email: string) =>
  Regex.EMAIL_VALIDATE.test(email) && email.length <= InputMaxLength.MAX_LENGTH_EMAIL;

export const getIsPhoneValid = (phone: string) => Regex.PHONE.test(phone);

export const getIsNameValid = (value: string) =>
  value.length >= InputMinLength.NAME &&
  value.length <= InputMaxLength.MAX_LENGTH_NAME &&
  Regex.NAME_VALIDATE.test(value);
