import { getIsDateValid, getIsEmailValid, getIsNameValid, getIsPhoneValid } from 'src/utils';
import type { IContactPersonRequiredFields } from 'src/interfaces';

export const checkIsPersonValidValue = (field: IContactPersonRequiredFields, value: string) => {
  switch (field) {
    case 'addressString':
      return !!value;
    case 'dateOfBirth':
      return getIsDateValid(value);
    case 'email':
      return getIsEmailValid(value);
    case 'firstName':
    case 'lastName':
      return getIsNameValid(value);
    case 'phone':
      return getIsPhoneValid(value);
    default:
      return true;
  }
};
