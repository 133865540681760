import React from 'react';
import classNames from 'classnames';

import BurialType from '../burial-type';

import { AVAILABLE_LOT_TYPES } from 'src/constants';

import './burial-types-list.scss';

const BurialTypesList = () => {
  const burialTypesClasses = classNames(
    'burial-types-list__items',
    AVAILABLE_LOT_TYPES.length === 1 && 'burial-types-list__items_single-item',
    AVAILABLE_LOT_TYPES.length === 2 && 'burial-types-list__items_two-items'
  );

  return (
    <nav className="burial-types-list">
      <ul className={burialTypesClasses}>
        {AVAILABLE_LOT_TYPES.map((type) => (
          <li className="burial-types-list__item" key={type}>
            <BurialType type={type} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default BurialTypesList;
