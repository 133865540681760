import React from 'react';
import classNames from 'classnames';

import Dropdown from 'src/components/ui/dropdown';
import Label from 'src/components/ui/label';
import Range from 'src/components/ui/range';
import { ReactComponent as Arrow } from 'src/assets/icons/chevron-down.svg';

import { Key } from 'src/constants';
import useKeyUp from 'src/hooks/use-key-up';
import useKeyUpOutside from 'src/hooks/use-key-up-outside';
import useOnClickOutside from 'src/hooks/use-on-click-outside';
import { formatCurrency, getOnlyNumbers } from 'src/utils';
import type { RangeSelectProps } from './range-select.props';

import './range-select.scss';

const RangeSelect = ({
  className,
  desktopSize,
  dropdownClassName,
  handleChangeValues,
  label,
  maxValue,
  minValue,
  range,
  size = 'regular',
}: RangeSelectProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [rangeEndValue, setRangeEndValue] = React.useState('');
  const [rangeStartValue, setRangeStartValue] = React.useState('');
  const wrapperRef = React.useRef(null);
  const onDropdownClose = () => {
    setTimeout(() => setIsOpen(false), 0);
  };

  useOnClickOutside(wrapperRef, onDropdownClose, !isOpen);
  useKeyUpOutside(Key.ENTER, wrapperRef, onDropdownClose, !isOpen);
  useKeyUp(Key.ESCAPE, onDropdownClose, !isOpen);

  React.useEffect(() => {
    setRangeStartValue(`${range?.from ?? ''}`);
    setRangeEndValue(`${range?.to ?? ''}`);
  }, [range]);

  const inputValue =
    range && range.from && range.to
      ? `${formatCurrency(range.from)} - ${formatCurrency(range.to)}`
      : '';

  const resetValues = React.useCallback(() => {
    setRangeEndValue('');
    setRangeStartValue('');
    handleChangeValues(0, 0);
  }, [handleChangeValues]);

  const handleClick = React.useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, []);

  const handleBlurMaxValue = React.useCallback(
    ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => {
      const startValue = Number(rangeStartValue);
      let endValue = Math.min(Number(getOnlyNumbers(value)), maxValue);

      if (!value) {
        if (rangeStartValue) {
          endValue = maxValue;
        } else {
          return resetValues();
        }
      }

      if (value && rangeStartValue && endValue < startValue) {
        setRangeEndValue(`${startValue}`);
        handleChangeValues(startValue, startValue);
        return;
      }

      if (value && !rangeStartValue && endValue < minValue) {
        setRangeEndValue(`${minValue}`);
        handleChangeValues(minValue, minValue);
        return;
      }

      if (endValue < startValue) {
        endValue = startValue;
      }

      setRangeEndValue(`${endValue}`);
      handleChangeValues(startValue || minValue, endValue);
    },
    [handleChangeValues, maxValue, minValue, rangeStartValue, resetValues]
  );

  const handleBlurMinValue = React.useCallback(
    ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => {
      const startValue = Math.max(Math.min(Number(getOnlyNumbers(value)), maxValue), minValue);
      const endValue = Number(rangeEndValue);

      if (!value && !rangeEndValue) {
        return resetValues();
      }

      if (value && rangeEndValue && startValue > endValue) {
        setRangeEndValue(`${startValue}`);
        handleChangeValues(startValue, startValue);
        return;
      }

      setRangeStartValue(`${startValue}`);
      handleChangeValues(startValue, endValue || maxValue);
    },
    [handleChangeValues, maxValue, minValue, rangeEndValue, resetValues]
  );

  const handleChangeEndValue = React.useCallback((value: string) => {
    setRangeEndValue(getOnlyNumbers(value));
  }, []);

  const handleChangeStartValue = React.useCallback((value: string) => {
    setRangeStartValue(getOnlyNumbers(value));
  }, []);

  const rangeSelectClasses = classNames(
    'range-select',
    className,
    desktopSize && `range-select_desktop-${desktopSize}`,
    isOpen && 'range-select_open',
    size === 'small' && 'range-select_small'
  );

  return (
    <div className={rangeSelectClasses} ref={wrapperRef}>
      <button className="range-select__button" onClick={handleClick}>
        <span className="range-select__button-wrapper">
          {label && !inputValue ? (
            <Label className="range-select__label label_placeholder" isSpan label={label} />
          ) : null}
          <span className="range-select__value" dir="auto">
            {inputValue}
          </span>
          <Arrow className="range-select__icon" />
        </span>
      </button>
      {isOpen && (
        <Dropdown className={dropdownClassName}>
          <Range
            startValue={formatCurrency(rangeStartValue)}
            endValue={formatCurrency(rangeEndValue)}
            minValue={`${minValue}`}
            maxValue={`${maxValue}`}
            handleBlurMinValue={handleBlurMinValue}
            handleBlurMaxValue={handleBlurMaxValue}
            setStartValue={handleChangeStartValue}
            setEndValue={handleChangeEndValue}
          />
        </Dropdown>
      )}
    </div>
  );
};

export default React.memo(RangeSelect);
