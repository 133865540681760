import React from 'react';
import { Link } from 'react-router-dom';

import ExternalLink from 'src/components/common/external-link';

import { AppRoute, CEMETERY_LINK } from 'src/constants';
import type { CemeteryLogoLinkProps } from './cemetery-logo-link.props';

const CemeteryLogoLink = ({ className, isFooter, children }: CemeteryLogoLinkProps) => {
  return isFooter && CEMETERY_LINK ? (
    <ExternalLink className={className} href={CEMETERY_LINK}>
      {children}
    </ExternalLink>
  ) : (
    <Link className={className} to={AppRoute.MAIN_PAGE}>
      {children}
    </Link>
  );
};

export default CemeteryLogoLink;
