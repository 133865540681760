import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

import {
  AVAILABLE_LOT_TYPES,
  CURRENCY_CENTS_COUNT,
  DEFAULT_LOT_TYPE,
  LotType,
  LotTypeShown,
  Rights,
  RightsListByLotType,
  RightsType,
  SortDirection,
  TYPE_OF_LOTS,
} from 'src/constants';
import { addAllOption, getInitialFilters } from 'src/utils';
import type { IOption, IRange } from 'src/interfaces';

const getDefaultTypesList = () => {
  const result: any = {};

  TYPE_OF_LOTS.forEach((type) => {
    result[type] = RightsListByLotType[type];
  });

  return JSON.stringify(result);
};

class FilterStore {
  init() {
    const { priceRange, rights, type, sort } = getInitialFilters();
    this.setPriceRange(priceRange);
    this.setRights(rights);
    this.setSort(sort);
    this.setType(type);
    this.setLoading(false);
  }

  isLoading = true;
  priceRange: IRange | null = null;
  rights: RightsType | null = null;
  sort: SortDirection | null = null;
  type: LotType | null = DEFAULT_LOT_TYPE;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    void makePersistable(this, {
      name: 'interactive-lot-selector-filters',
      properties: ['priceRange', 'rights', 'sort', 'type'],
      storage: window.localStorage,
      expireIn: 86400000, // One day in milliseconds
      removeOnExpiration: true,
    });
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setPriceRange(price: IRange | null) {
    this.priceRange = price;
  }

  setRights(rights: RightsType | null) {
    this.rights = rights;
  }

  setSort(sort: SortDirection | null) {
    this.sort = sort;
  }

  setType(type: LotType | null) {
    this.type = type;
  }

  get rightsOptions(): IOption[] {
    const selectedTypeRights = this.type ? RightsListByLotType[this.type] : [];
    return addAllOption(
      selectedTypeRights.map((rightsType) => ({ id: rightsType, value: Rights[rightsType] }))
    );
  }

  get typesOptions(): IOption[] {
    return addAllOption(
      AVAILABLE_LOT_TYPES.map((type) => ({
        id: type,
        value: LotTypeShown[type],
      })),
      'All Types'
    );
  }

  get apiParams() {
    const typesList = this.type
      ? JSON.stringify({
          [this.type]: this.rights ? [this.rights] : RightsListByLotType[this.type],
        })
      : getDefaultTypesList();

    return {
      typesList,
      sort: JSON.stringify({ price: this.sort || SortDirection.ASC }),
      ...(this.priceRange && {
        priceRange: JSON.stringify({
          from: this.priceRange.from * CURRENCY_CENTS_COUNT,
          to: this.priceRange.to * CURRENCY_CENTS_COUNT,
        }),
      }),
    };
  }

  handleChangePriceRange(from: number = 0, to: number = 0) {
    if (from !== this.priceRange?.from || to !== this.priceRange?.to) {
      this.setPriceRange(from || to ? { from, to } : null);
    }
  }

  handleChangeRights(rights: string) {
    if (rights !== this.rights) {
      this.setRights((rights as RightsType) || null);
    }
  }

  handleChangeSort(sort: SortDirection) {
    if (sort !== this.sort) {
      this.setSort(sort);
    }
  }

  handleChangeType(type: string) {
    if (type !== this.type) {
      this.setType((type as LotType) || null);
      this.setRights(null);
    }
  }

  updateFilters(searchFilters: any) {
    if (searchFilters.type) {
      this.handleChangeType(searchFilters.type);
    }
    if (searchFilters.rights) {
      this.handleChangeRights(searchFilters.rights);
    }
    if (searchFilters.priceRange) {
      this.handleChangePriceRange(searchFilters.priceRange.from, searchFilters.priceRange.to);
    }
    if (searchFilters.sort) {
      this.handleChangeSort(searchFilters.sort);
    }
  }

  clearFilters() {
    this.setPriceRange(null);
    this.setRights(null);
    this.setSort(null);
    this.setType(DEFAULT_LOT_TYPE);
  }

  clearStore() {
    void clearPersistedStore(this);
    this.clearFilters();
    this.setLoading(true);
  }
}

export default new FilterStore();

export { type FilterStore };
