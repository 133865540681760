import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Select from 'src/components/ui/select';

import filterStore from 'src/stores/filter-store';

import { SortDirection } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import useIsTablet from 'src/hooks/use-is-tablet';
import type { MapSortProps } from './map-sort.props';

import './map-sort.scss';

const MapSort = observer(({ className, isDisabled }: MapSortProps) => {
  const { getLocalization } = useLocalization();
  const { handleChangeSort, sort } = filterStore;
  const isTablet = useIsTablet();
  const label = isTablet ? `${getLocalization('Sorted by')}:` : `${getLocalization('Sort')}:`;

  const SORT_ITEMS = [
    { id: SortDirection.ASC, value: getLocalization('Ascending Price') },
    { id: SortDirection.DESC, value: getLocalization('Descending Price') },
  ];

  const handleSortChange = (val: string) => {
    if (val) {
      handleChangeSort(val as SortDirection);
    }
  };

  return (
    <div className={classNames('map-sort', className)}>
      <span className="map-sort__label">{label}</span>
      <Select
        isBorderless
        isDisabled={isDisabled}
        items={SORT_ITEMS}
        value={sort || SortDirection.ASC}
        onChange={handleSortChange}
      />
    </div>
  );
});

export default React.memo(MapSort);
