export const CYRILLIC_SYMBOLS_REG_EXP = /[а-яё]/gi;

export const InputMinLength = {
  NAME: 1,
  TEXT: 1,
};

export const InputMaxLength = {
  MAX_LENGTH_APT: 10,
  MAX_LENGTH_COMMENT: 256,
  MAX_LENGTH_EMAIL: 254,
  MAX_LENGTH_NAME: 30,
};
