import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

import { DEFAULT_LOCALE, IS_WITH_LOCALIZATION, PUBLIC_URL } from 'src/constants';
import type {
  LocalizationContextProps,
  LocalizationProviderProps,
} from './localization-context.props';

interface ILocalization {
  [key: string]: string;
}

const LocalizationContext = createContext<LocalizationContextProps>({
  locale: DEFAULT_LOCALE,
  isLocalizationLoading: false,
  getLocalization: (text: string) => text,
  setLocale: (_locale: string) => {},
});

const filterStringValues = (obj: Record<string, any>) => {
  return Object.keys(obj)
    .filter((key) => typeof obj[key] === 'string')
    .reduce<Record<string, string>>((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});
};

export const LocalizationProvider = ({ children }: LocalizationProviderProps) => {
  const [isLocalizationLoading, setIsLocalizationLoading] = useState<boolean>(false);
  const [locale, setLocale] = useState<TLocalesType>(DEFAULT_LOCALE);
  const [localization, setLocalization] = useState<ILocalization>({});

  useEffect(() => {
    const fetchLocalizationData = async () => {
      setIsLocalizationLoading(true);
      try {
        const response = await axios.get<ILocalization>(
          `${PUBLIC_URL}/localization/${locale}.json`
        );
        setLocalization(filterStringValues(response.data));
      } catch (e) {
        console.log(e);
      } finally {
        setIsLocalizationLoading(false);
      }
    };

    if (IS_WITH_LOCALIZATION) {
      void fetchLocalizationData();
    }
  }, [locale]);

  const getLocalization = (text: string): string => {
    if (!localization) {
      return text;
    }
    const lowerCaseText = text.toLowerCase();
    const translatedKey = Object.keys(localization).find((k) => k.toLowerCase() === lowerCaseText);

    if (translatedKey && localization[translatedKey]) {
      return localization[translatedKey];
    }

    return text;
  };

  return (
    <LocalizationContext.Provider
      value={{ isLocalizationLoading, getLocalization, setLocale, locale }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => useContext(LocalizationContext);
