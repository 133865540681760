import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import CopyToClipboardButton from '../copy-to-clipboard-button';
import { ReactComponent as CopyIcon } from 'src/assets/icons/share/copy.svg';
import { ReactComponent as EmailIcon } from 'src/assets/icons/share/email.svg';
import { ReactComponent as FacebookIcon } from 'src/assets/icons/share/facebook.svg';
import { ReactComponent as TwitterIcon } from 'src/assets/icons/share/twitter.svg';
import { ReactComponent as WhatsappIcon } from 'src/assets/icons/share/whatsapp.svg';

import { useLocalization } from 'src/contexts/localization-context';
import type { ShareButtonConfigProps, ShareButtonProps, ShareButtonType } from 'src/interfaces';

import './share-button.scss';

const SHARE_BUTTONS: Record<ShareButtonType, ShareButtonConfigProps> = {
  copy: {
    label: 'Copy URL',
    button: CopyToClipboardButton,
    icon: CopyIcon,
  },
  email: { label: 'Email', button: EmailShareButton, icon: EmailIcon },
  facebook: {
    label: 'Facebook',
    button: FacebookShareButton,
    icon: FacebookIcon,
  },
  twitter: { label: 'Twitter', button: TwitterShareButton, icon: TwitterIcon },
  whatsapp: {
    label: 'WhatsApp',
    button: WhatsappShareButton,
    icon: WhatsappIcon,
  },
};

function ShareButton({ title, type, url }: ShareButtonProps<ShareButtonType>) {
  const { button: Button, icon: Icon, label } = SHARE_BUTTONS[type];
  const { getLocalization } = useLocalization();
  return (
    <div className="share-button">
      <Button url={url} title={title} className="share-button__button">
        <span className="share-button__icon">
          <Icon />
        </span>
      </Button>
      <div className="share-button__label">{getLocalization(label)}</div>
    </div>
  );
}

export default ShareButton;
