import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import CemeteryMap from 'src/components/common/cemetery-map';
import Header from 'src/components/common/header';
import MapFiltersWrapper from './components/map-filters-wrapper';
import MapLots from './components/map-lots';
import MapSort from './components/map-sort';

import filterStore from 'src/stores/filter-store';
import globalAppStore from 'src/stores/global-app-store';
import LotsViewStore from 'src/stores/lots-view-store';

import { useHeader } from 'src/contexts/header-context';
import { useLocalization } from 'src/contexts/localization-context';
import useIsLaptop from 'src/hooks/use-is-laptop';
import useWindowSize from 'src/hooks/use-window-size';

import './map-view-page.scss';

const FILTERS_DESKTOP_HEIGHT = 70;

const MapViewPage = observer(() => {
  const { clearStore, init } = filterStore;
  const [store] = useState(() => new LotsViewStore());
  const {
    lots,
    inGroundLots,
    mausoleumCoords,
    isLoading,
    selectedLot,
    resetSelectedLot,
    setSelectedLot,
  } = store;
  const isLaptop = useIsLaptop();
  const windowSize = useWindowSize();
  const { cemetery } = globalAppStore;
  const { getLocalization } = useLocalization();
  const { headerHeight } = useHeader();
  const mapHeight = `${windowSize.height - headerHeight - FILTERS_DESKTOP_HEIGHT}px`;

  useEffect(() => {
    init();

    return () => clearStore();
  }, [clearStore, init]);

  return (
    <div className="map-view-page">
      <Header actions="phone" isFullWidth />

      <MapFiltersWrapper />

      <div className="map-view-page__content">
        <div className="map-view-page__list">
          <div className="map-view-page__list-wrapper">
            <div className="map-view-page__list-count">
              {`${getLocalization('Lots Found')}:`}{' '}
              <span className="map-view-page__list-count-value">{lots.length}</span>
            </div>
            <MapSort className="map-view-page__sort" isDisabled={isLoading} />
          </div>
          <MapLots
            className="map-view-page__list-lots"
            handleCardClick={setSelectedLot}
            isLoading={isLoading}
            lots={lots}
            selectedLot={selectedLot}
          />
        </div>

        {isLaptop && cemetery?.coords && (
          <CemeteryMap
            center={cemetery.coords}
            cemetery={cemetery}
            height={mapHeight}
            width="100%"
            lots={inGroundLots}
            mausoleumsCoords={mausoleumCoords}
            resetSelectedLot={resetSelectedLot}
            selectedLot={selectedLot}
            handleLotMarkerClick={setSelectedLot}
          />
        )}
      </div>
    </div>
  );
});

export default MapViewPage;
