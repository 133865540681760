import React from 'react';
import { observer } from 'mobx-react-lite';

import Chip from 'src/components/ui/chip';

import filterStore from 'src/stores/filter-store';

import { LotTypeShown, Rights } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import { formatPriceRange } from 'src/utils';

import './filters-view.scss';

const FiltersView = observer(() => {
  const { getLocalization } = useLocalization();
  const { handleChangePriceRange, handleChangeRights, handleChangeType, priceRange, rights, type } =
    filterStore;

  return (
    <div className="filters-view scrollbar scrollbar_horizontal">
      {priceRange && (
        <Chip
          isWithClose
          text={formatPriceRange(priceRange)}
          onClick={() => handleChangePriceRange(0, 0)}
        />
      )}
      {type && (
        <Chip
          isWithClose
          text={getLocalization(LotTypeShown[type])}
          onClick={() => handleChangeType('')}
        />
      )}
      {rights && (
        <Chip
          isWithClose
          text={getLocalization(Rights[rights])}
          onClick={() => handleChangeRights('')}
        />
      )}
    </div>
  );
});

export default FiltersView;
