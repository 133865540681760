import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import CemeteryLogo from 'src/components/common/cemetery-logo';
import Button from 'src/components/ui/button';
import FindAnotherLotButton from './components/find-another-lot-button';
import { ReactComponent as PhoneIcon } from 'src/assets/icons/phone-icon.svg';

import globalAppStore from 'src/stores/global-app-store';

import { LogoTextLocation } from 'src/constants';
import { useHeader } from 'src/contexts/header-context';
import useIsTablet from 'src/hooks/use-is-tablet';
import { formatApiToPhone } from 'src/utils';
import type { HeaderProps } from './header.props';

import './header.scss';

const Header = observer(({ actions, className, isFullWidth }: HeaderProps) => {
  const isTablet = useIsTablet();
  const { phone } = globalAppStore.cemeteryInfo;
  const withButtons = isTablet && actions;
  const { headerRef } = useHeader();

  return (
    <header
      ref={headerRef}
      className={classNames(
        'header',
        isTablet && 'header_desktop',
        withButtons && 'header_with-buttons',
        isFullWidth && 'header_full-width',
        className
      )}
    >
      <CemeteryLogo textLocation={LogoTextLocation.SIDE} />

      {withButtons && (
        <nav className="header__nav">
          <Button
            className="header__phone-button"
            icon={<PhoneIcon />}
            href={`tel:+${phone}`}
            label={formatApiToPhone(phone)}
            size="normal"
            tag="a"
            theme="outlined"
          />
          {actions === 'all' && <FindAnotherLotButton />}
        </nav>
      )}
    </header>
  );
});

export default Header;
