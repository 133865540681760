import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import NotFoundPage from 'src/pages/not-found-page';
import Footer from 'src/components/common/footer';
import Header, { FindAnotherLotButton } from 'src/components/common/header';

import reservationStore from 'src/stores/reservation-store';

import { FooterTheme } from 'src/constants';
import useIsTablet from 'src/hooks/use-is-tablet';
import type { LotLayoutProps } from './lot-layout.props';

import './lot-layout.scss';

const LotLayout = observer(({ children, className, isLotPage }: LotLayoutProps) => {
  const isTablet = useIsTablet();
  const withButtonOnPage = isLotPage && !isTablet;

  if (reservationStore.isPageNotFound) {
    return <NotFoundPage />;
  }

  return (
    <div className={classNames('lot-layout', className)}>
      <Header actions={isLotPage ? 'all' : undefined} />
      <div className="lot-layout__scrollable-block scrollbar">
        <div
          className={classNames(
            'lot-layout__content',
            withButtonOnPage && 'lot-layout__content_with-button'
          )}
        >
          {children}
          {withButtonOnPage && <FindAnotherLotButton className="lot-layout__find-button" />}
          <Footer theme={FooterTheme.DARK} />
        </div>
      </div>
    </div>
  );
});

export default LotLayout;
