import React, { FocusEvent, memo, useCallback, useRef, useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import classNames from 'classnames';

import Input from 'src/components/ui/input';

import { InputMaxLength } from 'src/constants';
import { useGoogleMaps } from 'src/contexts/google-maps-context';
import { useLocalization } from 'src/contexts/localization-context';
import type { AddressInputProps } from './address-input.props';

import './address-input.scss';

function AddressInput({
  apartmentValue,
  className,
  inputClassName,
  isDisabled,
  isValid,
  label,
  onApartmentChange,
  onBlur,
  onChange,
  placeholder,
  textError,
  value,
}: AddressInputProps) {
  const inputWrapper = useRef<Autocomplete>(null);
  const { isLoaded } = useGoogleMaps();
  const { getLocalization } = useLocalization();
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

  const handleFocus = useCallback(() => {
    const wrapper = inputWrapper.current?.containerElement.current;
    if (wrapper && autocomplete) {
      const suggestionsContainer = getAutocompleteContainer(autocomplete);
      if (suggestionsContainer) {
        wrapper.appendChild(suggestionsContainer);
      }
    }
  }, [autocomplete]);

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    onBlur?.(event.target.value);
  };

  const handleLoad = useCallback((autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  }, []);

  const inputClasses = classNames('address-input', inputClassName);

  const renderInput = () => (
    <Input
      autoComplete="off"
      className={inputClasses}
      isDisabled={isDisabled}
      isValid={isValid}
      label={label}
      onBlur={handleBlur}
      onChange={onChange}
      onFocus={handleFocus}
      placeholder={placeholder}
      textError={textError}
      value={value}
    />
  );

  const renderApartmentInput = () => (
    <Input
      isDisabled={isDisabled}
      className={classNames(inputClasses, 'address-input__apartment')}
      onChange={onApartmentChange}
      label={label ? getLocalization('Apt / Po') : ''}
      maxLength={InputMaxLength.MAX_LENGTH_APT}
      value={apartmentValue}
    />
  );

  const wrapperClasses = classNames('address-input__container', className);

  if (!isLoaded || isDisabled) {
    return (
      <div className={wrapperClasses}>
        {renderInput()}
        {renderApartmentInput()}
      </div>
    );
  }

  return (
    <div className={wrapperClasses}>
      <Autocomplete
        className="address-input__autocomplete-wrapper"
        ref={inputWrapper}
        onLoad={handleLoad}
      >
        {renderInput()}
      </Autocomplete>
      {renderApartmentInput()}
    </div>
  );
}

function getAutocompleteContainer(autocomplete: any) {
  const place: Record<string, any> = autocomplete?.gm_accessors_?.place;
  if (!place) {
    return null;
  }

  const placeKey = Object.keys(place).find(
    (value) => typeof place[value] === 'object' && place[value].hasOwnProperty('gm_accessors_')
  );
  if (!placeKey) {
    return null;
  }

  const input: Record<string, any> = place[placeKey]?.gm_accessors_?.input[placeKey];
  if (!input) {
    return null;
  }

  return Object.values(input).find((value) => value.classList?.contains('pac-container'));
}

export default memo(AddressInput);
