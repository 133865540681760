import React from 'react';

import type { InputPlaceholderProps } from './input-placeholder.props';

import './input-placeholder.scss';

function InputPlaceholder({ placeholder, hide }: InputPlaceholderProps) {
  if (hide || !placeholder) {
    return null;
  }

  return <span className="input-placeholder">{placeholder}</span>;
}

export default InputPlaceholder;
