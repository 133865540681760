import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Loader from 'src/components/common/loader';
import LotMap from 'src/components/common/lot-map';
import IconButton from 'src/components/ui/icon-button';
import { ReactComponent as IconCancel } from 'src/assets/icons/cross-icon.svg';

import globalAppStore from 'src/stores/global-app-store';
import locationScreenStore from 'src/stores/location-screen-store';

import { useLocalization } from 'src/contexts/localization-context';
import useUnmountAnimation from 'src/hooks/use-unmount-animation';
import type { LocationScreenProps } from './location-screen.props';

import './location-screen.scss';

const LocationScreen = observer(({ lotName, coords }: LocationScreenProps) => {
  const { hide } = locationScreenStore;
  const { cemetery } = globalAppStore;
  const { unmountComponent, handleAnimationEnd, isUnmounting } = useUnmountAnimation(hide);
  const { getLocalization } = useLocalization();

  return (
    <div
      className={classNames('location-screen', isUnmounting && 'location-screen_hide')}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className="location-screen__wrapper">
        <div className="location-screen__header">
          <h2 className="location-screen__title">
            {getLocalization('Lot Location')} - <b>{lotName}</b>
          </h2>
          <IconButton
            className="location-screen__header-icon"
            ariaLabel={getLocalization('Hide location screen')}
            icon={<IconCancel />}
            onClick={unmountComponent}
          />
        </div>
        <div className="location-screen__body">
          {cemetery ? (
            <LotMap
              className="lot-page__map"
              cemetery={cemetery}
              coords={coords}
              height="100%"
              width="100%"
              isFullScreen
              handleFullScreenButtonClick={hide}
            />
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
});

export default LocationScreen;
