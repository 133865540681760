import React from 'react';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/arrow.svg';

import type { ArrowButtonProps } from './arrow-button.props';

import './arrow-button.scss';

function ArrowButton({ className, handleButtonClick, modifier, size = 40 }: ArrowButtonProps) {
  return (
    <button
      className={classNames(`arrow-button  arrow-button_${modifier}`, className)}
      aria-label={`Move ${modifier[0].toUpperCase()}${modifier.slice(1)}`}
      onClick={handleButtonClick}
    >
      <ArrowIcon className="arrow-button__icon" height={size} width={size} />
    </button>
  );
}

export default ArrowButton;
