import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';

import CemeteryLogo from 'src/components/common/cemetery-logo';
import ExternalLink from 'src/components/common/external-link';
import { ReactComponent as AfsLogo } from 'src/assets/icons/afs-logo.svg';
import { ReactComponent as AfsLogoText1 } from 'src/assets/icons/afs-logo-text1.svg';

import globalAppStore from 'src/stores/global-app-store';

import { CEMETERY_LINK, FooterTheme, LogoTextLocation, SOCIAL_LINKS } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import { getContactLinks } from 'src/utils';
import type { FooterProps } from './footer.props';

import './footer.scss';

const Footer = observer(({ className, theme }: FooterProps) => {
  const { getLocalization } = useLocalization();
  const { cemeteryInfo } = globalAppStore;

  const isManyLinks = SOCIAL_LINKS.length > 2;

  const footerClasses = classnames('footer', `footer_${theme}`, className);

  return (
    <footer className={footerClasses}>
      <div className="footer__container">
        {theme === FooterTheme.DARK && (
          <div className="footer__info-container">
            <CemeteryLogo textLocation={LogoTextLocation.SIDE} isFooter />

            <ul className="footer__contact-wrapper">
              {getContactLinks(cemeteryInfo).map(({ link, label, icon: Icon }) => (
                <li key={label} className="footer__contact-item">
                  <ExternalLink className="footer__contact-link" href={link}>
                    <Icon className="footer__contact-icon" />
                    <span className="footer__contact-text">{label}</span>
                  </ExternalLink>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className={classnames('footer__content', isManyLinks && 'footer__content_many')}>
          <ExternalLink
            className="footer__logo"
            href="https://allfuneral.com/"
            title="All Funeral Services"
          >
            <AfsLogo className="footer__logo-img" />
            <AfsLogoText1 className="footer__logo-text" />
          </ExternalLink>

          <ul className={classnames('footer__social', isManyLinks && 'footer__social_many')}>
            {SOCIAL_LINKS.map(({ url, logo: Logo, label }) => (
              <li key={label} className="footer__social-item">
                <ExternalLink className="footer__social-link" href={url || ''} ariaLabel={label}>
                  <Logo className="footer__social-icon" />
                </ExternalLink>
              </li>
            ))}
          </ul>

          <ul className="footer__organization-links">
            {theme === FooterTheme.LIGHT && (
              <li className="footer__organization-links-item">
                <ExternalLink className="footer__organization-link" href={CEMETERY_LINK}>
                  {getLocalization('Cemetery Website')}
                </ExternalLink>
              </li>
            )}
            <li className="footer__organization-links-item">
              <ExternalLink
                className="footer__organization-link"
                href={window.PRIVACY_POLICY_URL || ''}
              >
                {getLocalization('Privacy Policy')}
              </ExternalLink>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
