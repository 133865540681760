import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Loader from 'src/components/common/loader';
import LocationScreen from 'src/components/common/location-screen';
import LotMap from 'src/components/common/lot-map';
import Button from 'src/components/ui/button';
import LabeledValue from './components/labeled-value';
import LotGallery from './components/lot-gallery';
import SchemaHelmet from './components/schema-helmet';
import { ReactComponent as MapPinIcon } from 'src/assets/icons/map-pin.svg';
import { ReactComponent as ShareIcon } from 'src/assets/icons/share.svg';

import globalAppStore from 'src/stores/global-app-store';
import locationScreenStore from 'src/stores/location-screen-store';
import popupStore from 'src/stores/popup-store';
import reservationStore from 'src/stores/reservation-store';

import { AppRoute, LotTypeShown, PopupName, Rights } from 'src/constants';
import { useHeader } from 'src/contexts/header-context';
import { useLocalization } from 'src/contexts/localization-context';
import useIsTablet from 'src/hooks/use-is-tablet';
import useWindowSize from 'src/hooks/use-window-size';
import { callNativeSharing, formatCents, getLotDetails } from 'src/utils';

import './lot-page.scss';

const PADDING_TOP = 36;
const DESKTOP_GAP = 32;

const LotPage = observer(() => {
  const { getLocalization } = useLocalization();
  const { id } = useParams();
  const { height } = useWindowSize();
  const [stickyTopPosition, setStickyTopPosition] = useState(PADDING_TOP);
  const stickyBlockRef = useRef<HTMLDivElement | null>(null);
  const { headerHeight } = useHeader();

  useEffect(() => {
    if (id) {
      reservationStore.init(id);
    }
  }, [id]);

  const { cemetery } = globalAppStore;
  const { isShown: isLocationScreenShown, show } = locationScreenStore;
  const { isLoading, lot } = reservationStore;
  const {
    bank,
    block,
    description,
    mausoleum,
    number,
    photos,
    price,
    rights,
    row,
    section,
    tier,
    type,
  } = lot ?? {};
  const isMausoleum = !!mausoleum;

  const isTablet = useIsTablet();

  const handleShareButtonClick = async () => {
    if (!lot) {
      return;
    }
    const shareData = {
      title: `${LotTypeShown[lot.type]}${section ? ` ${section}` : ''} - ${formatCents(lot.price)}`,
      url: window.location.href,
    };
    const sharingText = getLotDetails(lot)
      .map(({ label, field }) => `${label}: ${field}`)
      .join(', ');

    const isNativeSharing = await callNativeSharing({ ...shareData, text: sharingText });
    if (!isNativeSharing) {
      popupStore.showPopup(PopupName.SHARE, shareData);
    }
  };

  useEffect(() => {
    if (stickyBlockRef.current && lot) {
      const heightDiff = height - stickyBlockRef.current.offsetHeight;

      if (heightDiff < PADDING_TOP + headerHeight) {
        setStickyTopPosition(heightDiff - DESKTOP_GAP - headerHeight);
      } else {
        setStickyTopPosition(PADDING_TOP);
      }
    }
  }, [headerHeight, height, lot, stickyBlockRef]);

  if (!cemetery) {
    return null;
  }

  return (
    <div className="lot-page">
      {lot ? <SchemaHelmet lot={lot} cemetery={cemetery} /> : null}
      {isLocationScreenShown && lot && (
        <LocationScreen
          coords={lot?.coords}
          lotName={`${type ? getLocalization(LotTypeShown[type]) : ''} ${lot.number}`}
        />
      )}
      <div
        className="lot-page__column-wrapper"
        ref={stickyBlockRef}
        style={{ top: stickyTopPosition }}
      >
        <div className="lot-page__info-wrapper">
          <section className="lot-page__info">
            <div className="lot-page__title-wrapper">
              <h1 className="lot-page__title">
                {type ? getLocalization(LotTypeShown[type]) : ''} {number ?? ''}
              </h1>

              <p className="lot-page__price">{price !== undefined ? formatCents(price) : ''}</p>
            </div>

            {description ? <p className="lot-page__description">{description}</p> : null}
          </section>

          <section className="lot-page__location-section">
            <h2 className="lot-page__sub-title lot-page__sub-title_location">
              {getLocalization('Location')}
            </h2>

            <div className="lot-page__location-content">
              <div className="lot-page__location-row">
                <LabeledValue
                  label={getLocalization('Cemetery')}
                  value={globalAppStore.cemeteryInfo.name}
                />
              </div>

              {isMausoleum ? (
                <div className="lot-page__location-row">
                  <LabeledValue
                    className="lot-page__labeled-value"
                    label={getLocalization('Mausoleum')}
                    value={mausoleum.name}
                  />
                </div>
              ) : null}

              <div className="lot-page__location-row lot-page__location-row_multiple">
                {isMausoleum ? (
                  <>
                    <LabeledValue
                      className="lot-page__labeled-value"
                      label={getLocalization('Bank')}
                      value={bank}
                    />

                    <LabeledValue
                      className="lot-page__labeled-value"
                      label={getLocalization('Tier')}
                      value={tier}
                    />

                    <LabeledValue
                      className="lot-page__labeled-value"
                      label={getLocalization('Row')}
                      value={row}
                    />
                  </>
                ) : (
                  <>
                    <LabeledValue
                      className="lot-page__labeled-value"
                      label={getLocalization('Section')}
                      value={section}
                    />

                    <LabeledValue
                      className="lot-page__labeled-value"
                      label={getLocalization('Block')}
                      value={block}
                    />
                  </>
                )}

                <LabeledValue
                  className="lot-page__labeled-value"
                  label={getLocalization('Rights')}
                  value={rights ? getLocalization(Rights[rights]) : ''}
                />
              </div>
            </div>

            <div className="lot-page__buttons-wrapper">
              <Button
                className="lot-page__button"
                data-label={getLocalization('Show on Map')}
                icon={<MapPinIcon />}
                label={isTablet ? getLocalization('Show on Map') : getLocalization('Show Map')}
                onClick={show}
                size="normal"
                tag="button"
                theme="ghost"
              />

              <Button
                className="lot-page__button"
                data-label={getLocalization('Share Lot')}
                icon={<ShareIcon />}
                label={isTablet ? getLocalization('Share Lot') : getLocalization('Share')}
                onClick={handleShareButtonClick}
                size="normal"
                theme="ghost"
              />
            </div>
          </section>
        </div>

        <section className="lot-page__reservation-section">
          <div className="lot-page__heading">
            <h2 className="lot-page__sub-title">{getLocalization('Lot reservation')}</h2>

            <p className="lot-page__reservation-text">
              {getLocalization(
                'Send your request for a lot reservation. We will process your request within one business day.'
              )}
            </p>
          </div>

          <Button
            className="lot-page__reservation-button"
            href={`${AppRoute.RESERVATION_PAGE}/${id}`}
            label={getLocalization('Request')}
            tag="link"
            target="_blank"
            theme="filled"
            size="oversize"
          />
        </section>
      </div>

      <div className="lot-page__gallery">
        {photos?.length ? <LotGallery listImages={photos} /> : null}

        <div className="lot-page__map-wrapper">
          {lot ? (
            <LotMap
              className="lot-page__map"
              cemetery={cemetery}
              coords={lot.coords}
              height={isTablet ? '440px' : '320px'}
              width="100%"
              isFullScreen={isLocationScreenShown}
              handleFullScreenButtonClick={show}
            />
          ) : (
            <Loader />
          )}
        </div>
      </div>

      {isLoading ? <Loader className="loader_position_fixed" isDebounce={false} /> : null}
    </div>
  );
});

export default LotPage;
