import React from 'react';
import { GoogleMap } from '@react-google-maps/api';
import classNames from 'classnames';

import { MAP_DEFAULT_ZOOM, MAP_LOT_DEFAULT_ZOOM, MAP_MAX_ZOOM_OUT } from 'src/constants';
import { useGoogleMaps } from 'src/contexts/google-maps-context';
import type { MapProps } from './map.props';

import './map.scss';

const mapOptions = {
  mapTypeId: 'satellite',
  minZoom: MAP_MAX_ZOOM_OUT,
  maxZoom: MAP_LOT_DEFAULT_ZOOM,
  fullscreenControl: false,
  keyboardShortcuts: false,
  mapTypeControl: false,
  rotateControl: false,
  streetViewControl: false,
};

function createControl(
  className: string,
  ariaLabel: string,
  handleClick: () => void
): HTMLButtonElement {
  const button = document.createElement('button');
  button.className = className;
  button.setAttribute('aria-label', ariaLabel);
  button.title = ariaLabel;
  button.addEventListener('click', handleClick);

  return button;
}

const Map = ({
  center,
  children,
  className,
  height,
  isFullScreen,
  onClick,
  onLoad,
  onZoomChanged,
  handleHomeButtonClick,
  handleFullScreenButtonClick,
  width,
  zoom = MAP_DEFAULT_ZOOM,
}: MapProps) => {
  const { isLoaded } = useGoogleMaps();

  const handleMapLoad = (map: google.maps.Map) => {
    if (map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].getLength() === 0) {
      const controlsWrapper = document.createElement('div');
      controlsWrapper.className = 'map__controls-wrapper';

      const homeButton = createControl(
        'map__control map__control_home',
        'Navigate to initial location',
        handleHomeButtonClick
      );

      const fullScreenButton =
        typeof handleFullScreenButtonClick === 'function'
          ? createControl(
              classNames(
                'map__control',
                isFullScreen ? 'map__control_fullscreen_popup' : 'map__control_fullscreen'
              ),
              'Toggle fullscreen',
              handleFullScreenButtonClick
            )
          : undefined;

      controlsWrapper.append(homeButton);
      if (fullScreenButton) {
        controlsWrapper.append(fullScreenButton);
      }
      controlsWrapper.addEventListener(
        'contextmenu',
        (e: MouseEvent) => {
          e.preventDefault();
        },
        true
      );

      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlsWrapper);
    }

    onLoad(map);
  };

  const containerStyle = {
    width,
    height,
  };

  return (
    <section className={classNames('map', className)} style={{ height, width }}>
      {isLoaded && (
        <GoogleMap
          center={center}
          mapContainerStyle={containerStyle}
          onClick={onClick}
          onLoad={handleMapLoad}
          onZoomChanged={onZoomChanged}
          options={mapOptions}
          zoom={zoom}
        >
          {children}
        </GoogleMap>
      )}
    </section>
  );
};

export default Map;
