import { mapApiToSection } from 'src/adapters';
import { formatApiToPhone, getAddress, getCoords, getPathsCoords } from 'src/utils';
import type { IApiCemetery, IApiSection, ICemetery, ILatLngCoords } from 'src/interfaces';

export const mapApiToCemeteryInfo = (
  { organization, location, name, schedule, tile_url, geometry, description }: IApiCemetery,
  sectionsList: IApiSection[] | null
): ICemetery => ({
  address: getAddress(organization.location),
  cemeteryPaths: getPathsCoords(geometry) as ILatLngCoords[][],
  location: location,
  description: description,
  ...(location.point ? { coords: getCoords(location.point.coordinates) } : null),
  email: organization.email,
  name: name || '',
  phone: formatApiToPhone(organization.phone),
  schedule: schedule,
  sections: sectionsList?.map(mapApiToSection) ?? [],
  tile: tile_url || '',
});
