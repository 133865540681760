import React from 'react';
import { observer } from 'mobx-react-lite';

import CemeteryLogo from 'src/components/common/cemetery-logo';
import Footer from 'src/components/common/footer';
import BurialTypesList from './components/burial-types-list';

import { FooterTheme, LogoTextLocation, PUBLIC_URL } from 'src/constants';

import './main-page.scss';

const BG_PATH = `${PUBLIC_URL}/main-bg/interactive-lot-selector`;
const BG_MOBILE_MAX_WIDTH = 1081;
const BG_LAPTOP_MAX_WIDTH = 1599;

const MainPage = observer(() => {
  const { MAIN_PAGE_TITLE, MAIN_PAGE_DESCRIPTION } = window;

  return (
    <div className="main-page">
      <picture>
        <source
          type="image/webp"
          srcSet={`${BG_PATH}/mobile.webp`}
          media={`(max-width: ${BG_MOBILE_MAX_WIDTH}px)`}
        />
        <source
          type="image/webp"
          srcSet={`${BG_PATH}/desktop.webp, ${BG_PATH}/desktop@2x.webp 2x`}
          media={`(max-width: ${BG_LAPTOP_MAX_WIDTH}px)`}
        />
        <source type="image/webp" srcSet={`${BG_PATH}/desktop@2x.webp`} />
        <source srcSet={`${BG_PATH}/mobile.jpg`} media={`(max-width: ${BG_MOBILE_MAX_WIDTH}px)`} />
        <source
          srcSet={`${BG_PATH}/desktop.jpg, ${BG_PATH}/desktop@2x.jpg 2x`}
          media={`(max-width: ${BG_LAPTOP_MAX_WIDTH}px)`}
        />
        <img className="main-page__bg" src={`${BG_PATH}/desktop@2x.jpg`} alt="Cemetery landscape" />
      </picture>
      <div className="main-page__wrapper scrollbar">
        <div className="main-page__content">
          <div className="main-page__cemetery-data">
            <CemeteryLogo textLocation={LogoTextLocation.BOTTOM} />

            <div className="main-page__info">
              <h1 className="main-page__title">{MAIN_PAGE_TITLE}</h1>
              <h2 className="main-page__description">{MAIN_PAGE_DESCRIPTION}</h2>
            </div>
          </div>

          <BurialTypesList />
        </div>

        <Footer theme={FooterTheme.LIGHT} />
      </div>
    </div>
  );
});

export default MainPage;
