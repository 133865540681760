import React from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';
import RangeSelect from 'src/components/ui/range-select';
import Select from 'src/components/ui/select';
import FilterChipsList from '../filter-chips-list';

import filterStore from 'src/stores/filter-store';
import globalAppStore from 'src/stores/global-app-store';

import { useLocalization } from 'src/contexts/localization-context';
import { getFiltersSearchParamsObject } from 'src/utils';
import type { MapFiltersProps } from './map-filters.props';

import './map-filters.scss';

const MapFilters = observer(({ isDesktop, onClose }: MapFiltersProps) => {
  const [, setSearchParams] = useSearchParams();

  const { getLocalization } = useLocalization();
  const { from: minPriceValue, to: maxPriceValue } = globalAppStore.priceRange;
  const {
    clearFilters,
    handleChangePriceRange,
    handleChangeRights,
    handleChangeType,
    isLoading,
    priceRange,
    rights,
    rightsOptions,
    sort,
    type,
    typesOptions,
  } = filterStore;

  const formattedRightsOptions = rightsOptions.map(({ id, value }) => ({
    id,
    value: getLocalization(value),
  }));

  React.useEffect(() => {
    if (!isLoading) {
      setSearchParams(getFiltersSearchParamsObject({ priceRange, rights, sort, type }));
    }
  }, [isLoading, priceRange, rights, setSearchParams, sort, type]);

  return (
    <section className="map-filters">
      <div className="map-filters__wrapper">
        <div className="map-filters__item map-filters__item_lot-type">
          <span className="map-filters__item-label">{getLocalization('Lot Type')}:</span>
          <FilterChipsList
            activeItem={type || ''}
            items={typesOptions}
            onSelectChip={handleChangeType}
          />
        </div>

        <div className="map-filters__item map-filters__item_select">
          <span className="map-filters__item-label">{getLocalization('Lot Details')}:</span>
          <Select
            desktopSize="small"
            isDisabled={!type || rightsOptions.length < 2}
            items={formattedRightsOptions}
            label={rights || rightsOptions.length === 1 ? '' : getLocalization('Rights')}
            onChange={handleChangeRights}
            size="regular"
            value={rights || (rightsOptions.length === 1 && rightsOptions[0].id) || ''}
          />
        </div>

        <div className="map-filters__item map-filters__item_select">
          <RangeSelect
            desktopSize="small"
            dropdownClassName={classNames(
              'map-filters__range-dropdown',
              !isDesktop && 'map-filters__range-dropdown_mobile'
            )}
            handleChangeValues={handleChangePriceRange}
            label={getLocalization('Price')}
            maxValue={maxPriceValue}
            minValue={minPriceValue}
            range={priceRange}
            size="regular"
          />
        </div>

        {onClose && (
          <Button
            className="map-filters__apply-button"
            desktopSize="flattened"
            label={getLocalization('Apply')}
            onClick={onClose}
            size="normal"
            theme="filled"
          />
        )}

        <Button
          desktopSize="flattened"
          label={getLocalization('Clear')}
          onClick={clearFilters}
          size="normal"
          theme="outlined"
        />
      </div>
    </section>
  );
});

export default React.memo(MapFilters);
