import type { IAddress, IGoogleGeocodeAddress } from 'src/interfaces';

export const mapGoogleAddress = (googleAddress?: IGoogleGeocodeAddress): IAddress | undefined => {
  if (!googleAddress) {
    return;
  }

  const { address_components = [], geometry } = googleAddress;
  const { lat, lng } = geometry.location;
  const initialTransformedAddress = {
    point: {
      type: 'Point',
      coordinates: [lng, lat],
    },
  };

  return address_components.reduce((transformedAddress, { long_name, short_name, types }) => {
    if (types.includes('locality')) {
      transformedAddress.city = short_name;
    } else if (types.includes('country')) {
      transformedAddress.country = short_name;
    } else if (types.includes('administrative_area_level_2')) {
      transformedAddress.county = short_name;
    } else if (types.includes('administrative_area_level_1')) {
      transformedAddress.state = short_name;
    } else if (types.includes('route')) {
      transformedAddress.street = long_name;
    } else if (types.includes('street_number')) {
      transformedAddress.building = long_name;
    } else if (types.includes('postal_code')) {
      transformedAddress.zip = long_name;
    }
    return transformedAddress;
  }, initialTransformedAddress as IAddress);
};
