export const CEMETERY_ID = window.CEMETERY_ID;

export const ApiRoute = {
  CEMETERY: `cemetery/${CEMETERY_ID}`,
  CEMETERY_FILTERS: `cemetery/${CEMETERY_ID}/filter_value`,
  CEMETERY_SECTIONS: `cemetery/${CEMETERY_ID}/sections`,
  LOT: 'lot',
  LOTS: `cemetery/${CEMETERY_ID}/lots`,
  LOT_REQUEST: `cemetery/${CEMETERY_ID}/lot_request`,
};
